<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit" ref="_form">
        <div class="is-flex">
          <ValidationProvider
            name="covenant_ans"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors[0] ? 'is-danger' : ''" class="mr-2">
              <b-input v-model="covenant.ans" placeholder="Ans"></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            name="covenant_name"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors[0] ? 'is-danger' : ''" class="mr-2">
              <b-input v-model="covenant.name" placeholder="Nome"></b-input>
            </b-field>
          </ValidationProvider>

          <b-button native-type="submit" type="is-primary">Enviar</b-button>
        </div>
      </form>
    </ValidationObserver>

    <b-table
      @click="setFilter"
      :row-class="(row, index) => row._id === covenant._id && 'is-info'"
      :data="covenants"
      :columns="columns"
    ></b-table>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      covenant: {},
      covenants: [],
      columns: [
        {
          field: "id",
          label: "ID",
          visible: false,
        },
        {
          field: "ans",
          label: "Ans",
        },
        {
          field: "name",
          label: "Nome",
        },
      ],
    };
  },
  created() {
    this.$http.get(`${process.env.VUE_APP_URI}_covenant`).then((response) => {
      this.covenants = response.data;
    });
  },
  methods: {
    onSubmit() {
      const self = this;

      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (!self.covenant._id)
          self.$http
            .post(`${process.env.VUE_APP_URI}_covenant`, self.covenant)
            .then((response) => {
              self.$set(self.covenant, "_id", response.data._id);
              self.covenants.push(self.covenant);
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-success",
              });
            });

        if (self.covenant._id)
          self.$http
            .put(
              `${process.env.VUE_APP_URI}_covenant/${self.covenant._id}`,
              self.covenant
            )
            .then(() => {
              self.covenants = self.covenants.map(function (v) {
                if (v._id == self.covenant._id) v = self.covenant;
                return v;
              });
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-info",
              });
            });
      });
    },
    setFilter(v) {
      this.covenant = JSON.parse(JSON.stringify(v));
    },
  },
};
</script>
<style>
tr.is-info {
  background: #167df0;
  color: #fff;
}
</style>